import { type FunctionComponent } from "react";

export const AlertStoke: FunctionComponent<{ readonly size?: number }> = ({ size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="alert">
      <path
        id="vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 3.13818C12.7419 3.13818 13.4199 3.54518 13.7679 4.20018L21.1069 18.0642C21.2695 18.3687 21.35 18.7103 21.3405 19.0553C21.331 19.4004 21.2319 19.737 21.0529 20.0322C20.6879 20.6382 20.0469 21.0002 19.3389 21.0002H4.66095C4.3159 21.0017 3.97641 20.9133 3.676 20.7435C3.37559 20.5737 3.12465 20.3286 2.94795 20.0322C2.76867 19.7371 2.6694 19.4005 2.65993 19.0553C2.65046 18.7102 2.73112 18.3686 2.89395 18.0642L10.2319 4.20118C10.5799 3.54518 11.2579 3.13818 11.9999 3.13818ZM11.9999 5.13718L4.66095 19.0002H19.3439L11.9999 5.13718ZM13.0009 10H11.0009V15H13.0009V10ZM12.9999 16H10.9999V18H12.9999V16Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
